import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F484cd3b6949a4be3acf0e7cc7e060f3a@o387980.ingest.sentry.io\u002F5224083",
    environment:"production",
    beforeSend:function(event, hint) {
        const error = hint.originalException;
        if (error && error.statusCode === 500) {
          return event;
        }
        return null;
      },
    release:"b6eafeea0200c755dfe5d601d6ee679489a88d79"
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
